import React from 'react'

import './Loader.css'

const Loader = () => {
  return (
    <>
    <div className='bodycontnt'>

    <svg className='svgcontn' viewBox="0 0 600 300">
 
  <symbol id="s-text">
    <text class="text--line" text-anchor="middle" x="50%" y="50%" dy=".35em">Interstellar</text>
  </symbol>
 
  <clippath id="cp-text">
    <text class="text--line" text-anchor="middle" x="50%" y="50%" dy=".35em">Interstellar</text>
  </clippath>
  
  <g class="shadow" clip-path="url(#cp-text)">
    <rect class="anim-shape anim-shape--shadow" width="100%" height="100%"></rect>
  </g>

  <g class="colortext" clip-path="url(#cp-text)">
    <rect class="anim-shape" width="100%" height="100%"></rect>
    <rect class="anim-shape" width="80%" height="100%"></rect>
    <rect class="anim-shape" width="60%" height="100%"></rect>
    <rect class="anim-shape" width="40%" height="100%"></rect>
    <rect class="anim-shape" width="20%" height="100%"></rect>
  </g>

  <use class="text--transparent" xlinkHref="#s-text"></use>
</svg>
    </div>
    </>
  )
}

export default Loader

