import React, { useState, Suspense, lazy } from 'react';
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { requestFirebaseNotificationPermission, onMessageListener } from './Firebase.js';
import ErrorBoundary from './ErrorBoundary';
import loader from '../src/assets/images/loader2.gif'
//import loader from '../src/assets/images/shail.gif'

import '../src/components/Loader/Loader'

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const KycInfos = lazy(() => import('./containers/Dashboard/KycInfos'));
const CreateAcc = lazy(() => import('./components/CreateAccount/CreateAcc'));
const CrAccOtp = lazy(() => import('./components/CreateAccount/CreateAccOtp'));
const Login = lazy(() => import('./components/Login/Login'));
const ResetEmail = lazy(() => import('./components/ForgtPas/ResetEmail/ResetEmail'));
const Otp = lazy(() => import('./components/ForgtPas/Otp/Otp'));
const ConfirmPas = lazy(() => import('./components/ForgtPas/ConfirmPas/ConfirmPas'));
const ReferralRegistration = lazy(() => import('./components/CreateAccount/ReferralRegistration.js'));
const Home = lazy(() => import('./containers/Home/Home'));
const EmailOTP = lazy(() => import('./components/2FA/disable2fa/emailOTP/EmailOTP'));
const Fa3 = lazy(() => import('./components/2FA/fa3/Fa3'));
const ChartView = lazy(() => import('./containers/Trading/Chart/ChartLiveOnApp'));
const Aboutus = lazy(() => import('./components/Dashboard/Aboutus'));
const RequireAuth = lazy(() => import('./containers/RequireAuth'));
const TermsConditions = lazy(() => import('./components/Dashboard/TermsConditions'));
const Refundpolicy = lazy(() => import('./components/Dashboard/Refundpolicy'));
const Sectioneight = lazy(() => import('./components/Dashboard/Faqs'));
const PrivacyPolicy = lazy(() => import('./components/Dashboard/PrivacyPolicy'));
const Amlpolicy = lazy(() => import('./components/Dashboard/Amlpolicy'));
const Invoice = lazy(() => import('./components/Invoice/Invoice'));
const Fee = lazy(() => import('./components/profileSetUp/fee/Fees'));


const ROLES = {
  'User': 2,
  'Editor': 1984,
  'Admin': 1,
  'suparUsers': 3
}
function App(props) {
  React.useEffect(() => {
    requestFirebaseNotificationPermission().then((firebaseToken) => {
    }).catch((err) => {
      console.log(err);
      return err;
    });
  }, [])
  React.useEffect(() => {
    localStorage.setItem('password78', props.token);
  }, []);


  let routes = (
    <ErrorBoundary>
      <Suspense fallback={
        <div className='supense' style={{ width: "200px" }}>
          {/* <Loader /> */}
          <img src={loader} alt='loader' />
        </div>}>
        <Routes>
          <Route path={"/confirm"} element={<ConfirmPas />} />
          <Route path={"/resetotp"} element={<Otp />} />
          <Route path={"/reset"} element={<ResetEmail />} />
          <Route exact path={"/createaccount/otp"} element={<CrAccOtp />} />
          <Route exact path={"/createaccount"} element={<CreateAcc />} />
          <Route exact path={"/createaccount/:id"} element={<ReferralRegistration />} />
          <Route path={"/ChartLive/:coin/:ex"} element={<ChartView />} />
          <Route path="/" element={<Dashboard />} />
          <Route path={"/aboutus"} element={<Aboutus />} />
          <Route path="/KycInfos" element={<KycInfos />} />
          <Route path={"/trmsConditions"} element={<TermsConditions />} />

          <Route path={"/refpolicy"} element={<Refundpolicy />} />
          <Route path={"/faqs"} element={<Sectioneight />} />
          <Route path={"/prvacyplcy"} element={<PrivacyPolicy />} />
          <Route path={"/amlpolicy"} element={<Amlpolicy />} />
          <Route exact path='/fa/emailotp' element={<EmailOTP />} />
          <Route path="/fa/verification" element={<Fa3 />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/fess"} element={<Fee />} />
          <Route path={"/invoice"} element={<Invoice />} />


          {/* <Route exact path="/stake-home" Component={Stakehome} />
          <Route exact path="/stake-plans" Component={Plans} />
          <Route exact path="/stake-deposit-wallet" Component={StakeDepositWallet} /> */}



          <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.suparUsers]} />}>
            <Route path={"*"} element={<Home />} />
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>

  );



  return (
    <div className="App">
      {routes}
    </div>
  );
}



const mapStateToProps = (state) => {

  return ({
    token: state.token,
    type: state.user_type

  })
}


export default connect(mapStateToProps)(App);
